const Syntax = [
  { "label": "你", "icon": require('./icons/Orange/YOU.png'), "isFolder": false },
  { "label": "我", "icon": require('./icons/Orange/I.png'), "isFolder": false },
  { "label": "他", "icon": require('./icons/Orange/HE.png'), "isFolder": false },
  { "label": "她", "icon": require('./icons/Orange/SHE.png'), "isFolder": false },
  { "label": "它", "icon": require('./icons/Orange/IT.png'), "isFolder": false },
  { "label": "我们", "icon": require('./icons/Orange/WE.png'), "isFolder": false },
  { "label": "他们", "icon": require('./icons/Orange/THEY.png'), "isFolder": false },
  { "label": "这", "icon": require('./icons/Orange/THIS.png'), "isFolder": false },
  { "label": "那", "icon": require('./icons/Orange/THAT.png'), "isFolder": false },
  { "label": "大便", "icon": require('./icons/Orange/POOP.png'), "isFolder": false },
  { "label": "不客气", "icon": require('./icons/Orange/不客气.png'), "isFolder": false },
  { "label": "谢谢", "icon": require('./icons/Orange/谢谢.png'), "isFolder": false },
  
  { "label": "什么", "icon": require('./icons/White/WHAT.png'), "isFolder": false },
  { "label": "哪里", "icon": require('./icons/White/WHERE.png'), "isFolder": false },
  { "label": "谁", "icon": require('./icons/White/WHO.png'), "isFolder": false },

  { "label": "因为", "icon": require('./icons/Orange/BECAUSE.png'), "isFolder": false },
  { "label": "但是", "icon": require('./icons/Orange/但是.png'), "isFolder": false },
  { "label": "和", "icon": require('./icons/Orange/和.png'), "isFolder": false },
  { "label": "或", "icon": require('./icons/Orange/或.png'), "isFolder": false },

  { "label": "里面", "icon": require('./icons/Green/IN.png'), "isFolder": false },
  { "label": "外面", "icon": require('./icons/Green/OUT.png'), "isFolder": false },
  { "label": "上", "icon": require('./icons/Green/UP.png'), "isFolder": false },
  { "label": "下", "icon": require('./icons/Green/DOWN.png'), "isFolder": false },

  { "label": "是", "icon": require('./icons/Pink/IS.png'), "isFolder": false },
  { "label": "能", "icon": require('./icons/Pink/CAN.png'), "isFolder": false },
  { "label": "有", "icon": require('./icons/Pink/HAVE.png'), "isFolder": false },
  { "label": "来", "icon": require('./icons/Pink/COME.png'), "isFolder": false },
  { "label": "去", "icon": require('./icons/Pink/GO.png'), "isFolder": false },
  { "label": "吃", "icon": require('./icons/Pink/EAT.png'), "isFolder": false },
  { "label": "得到", "icon": require('./icons/Pink/GET.png'), "isFolder": false },
  { "label": "给", "icon": require('./icons/Pink/GIVE.png'), "isFolder": false }, 
  { "label": "知道", "icon": require('./icons/Pink/KNOW.png'), "isFolder": false },
  { "label": "喜欢", "icon": require('./icons/Pink/LIKE.png'), "isFolder": false },
  { "label": "看", "icon": require('./icons/Pink/LOOK.png'), "isFolder": false },
  { "label": "做", "icon": require('./icons/Pink/MAKE.png'), "isFolder": false },
  { "label": "需要", "icon": require('./icons/Pink/NEED.png'), "isFolder": false },
  { "label": "玩", "icon": require('./icons/Pink/PLAY.png'), "isFolder": false },
  { "label": "放", "icon": require('./icons/Pink/PUT.png'), "isFolder": false },
  { "label": "说", "icon": require('./icons/Pink/SAY.png'), "isFolder": false },
  { "label": "看见", "icon": require('./icons/Pink/SEE.png'), "isFolder": false },
  { "label": "停止", "icon": require('./icons/Pink/STOP.png'), "isFolder": false },
  { "label": "拿", "icon": require('./icons/Pink/TAKE.png'), "isFolder": false },
  { "label": "想", "icon": require('./icons/Pink/THINK.png'), "isFolder": false },
  { "label": "想要", "icon": require('./icons/Pink/WANT.png'), "isFolder": false },

  
  { "label": "好的", "icon": require('./icons/Blue/GOOD.png'), "isFolder": false },
  { "label": "坏的", "icon": require('./icons/Blue/BAD.png'), "isFolder": false },
  { "label": "不", "icon": require('./icons/Blue/NOT.png'), "isFolder": false },
  { "label": "现在", "icon": require('./icons/Blue/NOW.png'), "isFolder": false },

  // folder images
  { "label": "感觉", "icon": require('./icons/Feelings/感觉.png'), "isFolder": true },
  { "label": "人物", "icon": require('./icons/People/人物.png'), "isFolder": true },
  { "label": "食物", "icon": require('./icons/Food/食物.png'), "isFolder": true },
  { "label": "地点", "icon": require('./icons/Places/地点.png'), "isFolder": true },
  { "label": "动作", "icon": require('./icons/Actions/动作.png'), "isFolder": true },
  { "label": "描述", "icon": require('./icons/Describe/描述.png'), "isFolder": true },
  { "label": "对话", "icon": require('./icons/Chat/对话.png'), "isFolder": true },
];

export default Syntax;