const PlaceItems = [
    { "label": "公园", "icon": require('../icons/Places/公园.png'), "isFolder": false },
    { "label": "医院", "icon": require('../icons/Places/医院.png'), "isFolder": false },
    { "label": "商场", "icon": require('../icons/Places/商场.png'), "isFolder": false },
    { "label": "学校", "icon": require('../icons/Places/学校.png'), "isFolder": false },
    { "label": "家", "icon": require('../icons/Places/家.png'), "isFolder": false },
    { "label": "工作地点", "icon": require('../icons/Places/工作地点.png'), "isFolder": false },
    { "label": "返回", "icon": require('../icons/HOME.png'), "isFolder": true }
];

export default PlaceItems;
