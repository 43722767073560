const ChatItems = [
    { "label": "不好意思", "icon": require('../icons/Chat/不好意思.png'), "isFolder": false },
    { "label": "哈哈", "icon": require('../icons/Chat/哈哈.png'), "isFolder": false },
    { "label": "太酷了", "icon": require('../icons/Chat/太酷了.png'), "isFolder": false },
    { "label": "对不起", "icon": require('../icons/Chat/对不起.png'), "isFolder": false },
    { "label": "开玩笑的", "icon": require('../icons/Chat/开玩笑的.png'), "isFolder": false },
    { "label": "快点", "icon": require('../icons/Chat/快点.png'), "isFolder": false },
    { "label": "我有话说", "icon": require('../icons/Chat/我有话说.png'), "isFolder": false },
    { "label": "拜托", "icon": require('../icons/Chat/拜托.png'), "isFolder": false },
    { "label": "返回", "icon": require('../icons/HOME.png'), "isFolder": true }
];

export default ChatItems;
