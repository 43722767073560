const FoodItems = [
    { "label": "中餐", "icon": require('../icons/Food/中餐.png'), "isFolder": false },
    { "label": "早餐", "icon": require('../icons/Food/早餐.png'), "isFolder": false },
    { "label": "晚餐", "icon": require('../icons/Food/晚餐.png'), "isFolder": false },
    { "label": "水果", "icon": require('../icons/Food/水果.png'), "isFolder": false },
    { "label": "零食", "icon": require('../icons/Food/零食.png'), "isFolder": false },
    { "label": "饮料", "icon": require('../icons/Food/饮料.png'), "isFolder": false },
    { "label": "返回", "icon": require('../icons/HOME.png'), "isFolder": true }
];

export default FoodItems;
